import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

const Seo: React.FC<{
  url?: string
  seo?: {
    title?: string
    canonical?: string
    description?: string
    metaRobotsNoindex?: string
    metaRobotsNofollow?: string
  }
}> = ({ url, seo }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          url
          title
        }
      }
    }
  `)
  const baseUrl = data?.site?.siteMetadata?.url
  const baseUrlTrimmed = baseUrl
    ? baseUrl.endsWith('/')
      ? baseUrl.slice(0, -1)
      : baseUrl
    : ''

  const canonical = url ?? seo?.canonical

  const title = `${seo?.title ? `${seo.title} | ` : ''}${
    data?.site?.siteMetadata?.title
  }`

  return (
    <Helmet>
      <title>{title}</title>
      {canonical && (
        <link
          rel="canonical"
          href={
            canonical.match(/^https?:\/\//)
              ? canonical
              : // Yoast's default URLs are relative, starting with a slash.
                `${baseUrlTrimmed}${canonical}`
          }
        />
      )}
      {seo?.description && (
        <meta name="description" content={seo.description} />
      )}
      {/* {seo && (
        <meta
          name="robots"
          content={`${seo?.metaRobotsNoindex || 'noindex'}, ${
            seo?.metaRobotsNofollow || 'nofollow'
          }`}
        />
      )} */}
    </Helmet>
  )
}

export const query = graphql`
  fragment SeoFragment on WpPostTypeSEO {
    canonical
    title
    metaDesc
    focuskw
    metaRobotsNoindex
    metaRobotsNofollow
    opengraphAuthor
    opengraphDescription
    opengraphTitle
    opengraphDescription
    opengraphImage {
      sourceUrl
    }
    opengraphUrl
    opengraphSiteName
    opengraphPublishedTime
    opengraphModifiedTime
    twitterTitle
    twitterDescription
    twitterImage {
      sourceUrl
    }
  }
`

export default Seo
