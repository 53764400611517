import { PortableTextComponentsProvider } from '@portabletext/react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const ContentComponentsProvider: React.FC = ({ children }) => {
  const {
    allSanityImageAsset: { nodes: images },
  } = useStaticQuery(graphql`
    query {
      allSanityImageAsset {
        nodes {
          id
          altText
          gatsbyImageData
        }
      }
    }
  `)
  console.log(images)
  return (
    <PortableTextComponentsProvider
      components={{
        types: {
          image: ({ value }) => {
            const imageData = images.find(
              (x: any) => x.id === value.asset?._ref
            )
            console.log(value, imageData)
            return imageData ? (
              <div className="flex justify-center w-full">
                <GatsbyImage
                  alt={imageData.altText ?? ''}
                  image={imageData.gatsbyImageData}
                />
              </div>
            ) : (
              <></>
            )
          },
        },
        marks: {
          link: ({ children, value }) => {
            const rel = !value.href.startsWith('/')
              ? 'noreferrer noopener'
              : undefined
            return (
              <a href={value.href} rel={rel} target="_blank">
                {children}
              </a>
            )
          },
        },
      }}
    >
      {children}
    </PortableTextComponentsProvider>
  )
}

export default ContentComponentsProvider
