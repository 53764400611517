import { Helmet } from 'react-helmet'
import ContentComponentsProvider from '../components/Content'
import Footer from '../components/Footer'
import Header from '../components/Header'

const MainLayout: React.FC<{
  container?: boolean
  footerForm?: boolean
  invert?: boolean
}> = ({ container, children }) => {
  return (
    <div className="relative flex flex-col min-h-screen overflow-hidden font-body">
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      ></Helmet>
      <div className="flex-grow">
        <Header />
        <main className="mb-8">
          <div className={`${container ? 'container ' : ''}`}>
            <div>
              <ContentComponentsProvider>{children}</ContentComponentsProvider>
            </div>
          </div>
        </main>
      </div>
      <Footer invert />
    </div>
  )
}

export default MainLayout
