import { graphql, useStaticQuery } from 'gatsby'

const Footer: React.FC<{ invert?: boolean }> = ({ invert }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <footer className="relative w-full">
      <div className="relative z-20 w-full p-8 text-sm">
        <div
          className={
            'flex-1 text-right ' + (invert ? 'text-black' : 'text-white')
          }
        >
          © Copyright {new Date().getFullYear()} {data.site.siteMetadata.title}
        </div>
      </div>
    </footer>
  )
}

export default Footer
