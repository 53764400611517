import { Link } from 'gatsby'

const Header: React.FC = () => (
  <header className="relative">
    <div className="relative z-20 px-4 py-4 text-left md:py-8 md:px-10">
      <Link to="/" className="b">
        <div className="text-xs text-white uppercase">Web Growth Digital</div>
        <div className="text-white transition-colors text-8xl font-display hover:text-pink-500">
          WGD
        </div>
      </Link>
    </div>
  </header>
)

export default Header
